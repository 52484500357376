
import { Options, Vue } from 'vue-class-component';
import { BaseButton, BaseIcon, BaseSelectDropdown, LegalPolicy, NotificationStack } from '@/lib/components';
import FloatingBrandShapesLayout from '@/lib/layouts/FloatingBrandShapesLayout.vue';
import { useSessionStore } from '@/stores/session.store';
import { useUiStore } from '@/stores/ui.store';
import { useNotificationStore } from '@/stores/notification.store';
import { Customer, CustomerBranding } from '@/models';
import { INotification, IOption } from '@/lib';
import { UserService } from '@/services/api';
import { DEFAULT_LEGAL_REGION } from '@/constants/legal';
import { arrayUnique } from '@/helpers/array.helper';

@Options({
  components: {
    BaseButton,
    BaseIcon,
    FloatingBrandShapesLayout,
    LegalPolicy,
    NotificationStack,
    BaseSelectDropdown
  }
})
export default class AgreementPage extends Vue {
  loading = true;
  agreed = false;
  saving = false;
  selectedRegionId: string | null = null;
  sessionStore = useSessionStore();
  uiStore = useUiStore();
  notificationStore = useNotificationStore();
  userService = new UserService();

  get notifications() {
    return this.notificationStore.notifications;
  }

  get customers(): Customer[] {
    return this.sessionStore.policiesToAgreeTo;
  }

  get allLegalRegions(): Customer['legal_regions'] {
    const legalRegions = arrayUnique(this.customers
      .flatMap(({ legal_regions }) => legal_regions));
    return legalRegions?.length ? legalRegions : [DEFAULT_LEGAL_REGION];
  }

  get legalRegionsDropdownOptions(): IOption[] {
    return this.allLegalRegions.map((region) => ({
      value: region,
      label: this.$t(`platform.countries.${region.toUpperCase()}`)
    })).sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  get branding(): Customer['branding'][] {
    return [this.uiStore.headerBranding || CustomerBranding.BPM];
  }

  get acceptTermsLabel() {
    return this.$te(`platform.user-agreement.${this.branding}-accept-terms`)
      ? this.$t(`platform.user-agreement.${this.branding}-accept-terms`)
      : this.$t('platform.user-agreement.accept-terms');
  }

  created() {
    this.selectedRegionId = this.allLegalRegions[0] ?? DEFAULT_LEGAL_REGION;
    this.$watch('customers', () => {
      this.selectedRegionId = this.allLegalRegions[0] ?? DEFAULT_LEGAL_REGION;
    });
  }

  onNotificationsUpdate(notifications: INotification[]) {
    this.notificationStore.notifications = notifications;
  }

  async submit() {
    try {
      this.saving = true;
      await Promise.all(this.customers.map((customer) => this.userService.agreeToCustomerTermsOfService(customer.id)));
      this.sessionStore.currentUser = {
        ...this.sessionStore.currentUser,
        accepted_customers: [...this.sessionStore.currentUser.accepted_customers, ...this.customers]
      };

      if (this.sessionStore.currentUser?.is_admin) {
        await this.$router.push({ name: 'domain-admin' });
      } else if (this.sessionStore.currentOrganisationId) {
        await this.$router.push({
          name: 'patient-list',
          params: { organisationId: this.sessionStore.currentOrganisationId }
        });
      } else {
        await this.$router.push({ name: 'settings' });
      }
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.user-agreement.error-title'),
        label: this.$t('platform.user-agreement.error-message'),
        action: {
          label: 'Retry',
          onClick: () => this.submit()
        }
      });
    } finally {
      this.saving = false;
    }
  }
}
